<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-print"></i> Asset Sticker Generator</b-card-title>
			<b-card-sub-title>Manages the generation of stickers for asset(s) tagging</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Date From">
											<b-form-datepicker v-model="filterBy.dateFrom" locale="en" reset-button
												label-reset-button="Clear" :date-format-options="{
													year: 'numeric',
													month: 'short',
													day: '2-digit',
													weekday: 'short',
												}" :date-disabled-fn="dateFromDisabled" />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Date To">
											<b-form-datepicker v-model="filterBy.dateTo" locale="en" reset-button
												label-reset-button="Clear" :date-format-options="{
													year: 'numeric',
													month: 'short',
													day: '2-digit',
													weekday: 'short',
												}" />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Company">
											<v-select class="style-chooser" label="text" placeholder=" - Please select - " :options="allCompanyOptions"
												:reduce="(company) => company.value" v-model="filterBy.company">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Asset Code">
											<b-form-input id="assetCode" name="Asset Code" type="search" class="numFont"
												v-model="filterBy.assetCode"
												v-validate="{ regex: /^([a-zA-Z0-9\-])*$/ }"></b-form-input>
											<span v-show="errors.has('Asset Code')" class="help-block">{{
												errors.first('Asset Code') }}</span>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item @click="onClickGenerate" v-show="!isViewer">
								Generate Stickers
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls"
									:name="fileName + '.xls'">
									Export Sticker Generations in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv"
									:name="fileName + '.csv'">
									Export Sticker Generations to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<div class="row mt-3">
					<div class="col-md-12">
						<b-table ref="stickersTable" show-empty striped hover :items="items" :fields="fields"
							:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

							<template v-slot:cell(dateGenerated)="row">{{
								row.item.dateGenerated | toDateTimeString
							}}</template>

							<template v-slot:cell(quantity)="row">
								<span class="numFont">
									{{ row.item.quantity }}
								</span>
							</template>

							<template v-slot:cell(totalAssetCount)="row">
								<span class="numFont">
									{{ row.item.totalAssetCount }}
								</span>
							</template>

							<template v-slot:cell(stickerRange)="row">
								<span class="numFont">
									{{ row.item.stickerRange }}
								</span>
							</template>

							<template v-slot:cell(status)="row">
								<b-badge :variant="`${row.item.status == 'NEW' ? 'success' : 'danger'
									}`">{{ row.item.status }}</b-badge>
							</template>

							<template v-slot:cell(downloadFile)="row">
								<span class="text-nowrap">
									<b-button size="sm" variant="dark" v-b-tooltip.hover.top="'View Details'"
										class="mr-1" @click="row.toggleDetails">
										<i class="fa fa-eye"></i>
									</b-button>
									<b-button size="sm" variant="primary" v-b-tooltip.hover.top="'Download JSON File'"
										class="mr-1, download" :href="row.item.file.url" target="_blank">
										<i class="fa fa-download"></i>
									</b-button>
								</span>
							</template>

							<template slot="row-details" slot-scope="row">
								<StickerGeneratorDetailsView :row="row" />
							</template>
						</b-table>

						<b-row>
							<b-col md="8" sm="12" class="my-1">
								<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
									}}</span>
							</b-col>
							<b-col md="4" sm="12" class="my-1">
								<b-pagination align="right" :total-rows="totalRows" :per-page="perPage"
									v-model="currentPage" class="my-0" />
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</b-card>
		<GenerateStickerDialog @onGenerateSuccess="onGenerateSuccess" />
	</div>
</template>

<script>
// Component
import StickerGeneratorDetailsView from './stickerGenerator/StickerGeneratorDetailsView';
import GenerateStickerDialog from './stickerGenerator/GenerateStickerDialog';

// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// API
import stickerApi from '@/api/stickerApi';

// Others
import { mapGetters } from 'vuex';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
	name: 'StickerGenerator',
	components: {
		Loading,
		JsonExcel,
		StickerGeneratorDetailsView,
		GenerateStickerDialog,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'dateGenerated',
					label: 'Date Generated',
					sortable: true,
				},
				{
					key: 'assetType',
					label: 'Asset Type',
					sortable: true,
				},
				{
					key: 'assetTypeCode',
					label: 'Asset Type Code',
					sortable: true,
				},
				{
					key: 'quantity',
					label: 'Total # of Stickers',
					sortable: true,
				},
				{
					key: 'totalAssetCount',
					label: 'Total # of Assets',
					sortable: true,
				},
				{
					key: 'stickerRange',
					label: 'Sticker Range',
				},
				{
					key: 'status',
					label: 'Status',
				},
				{
					key: 'downloadFile',
					label: 'Download File',
				},
			],

			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			defaultFilterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				company: config.companyDefaultValue,
				assetCode: '',
			},
			filterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				company: config.companyDefaultValue,
				assetCode: '',
			},
			prevFilterBy: {},

			allCompanyOptions: [],

			allCompaniesObj: {},
			allAssetTypesObj: {},
			allStickersObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		...mapGetters('stickerGenerator', ['getStickers']),

		dateFrom() {
			const dateTo = moment();
			const dateFrom = dateTo.add(-30, 'days');
			return dateFrom.format('YYYY-MM-DD');
		},
		dateTo() {
			return moment().format('YYYY-MM-DD');
		},

		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				'Date Generated': 'Date Generated',
				Company: 'company',
				'Asset Type': 'assetType',
				'Asset Type Code': 'assetTypeCode',
				'Total # of Stickers': 'quantity',
				'Total # of Assets': 'totalAssetCount',
				'Sticker Range': 'stickerRange',
				Status: 'status',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'StickerGeneration-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Filter Access
				if (this.$store.getters.isScanner) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// show loading indicator
				this.isLoading = true;

				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.allCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
				this.allAssetTypesObj = { ...this.$store.getters.assetTypes };

				// Set Default Filter Options
				if (!this.isSuperAdmin) {
					this.defaultFilterBy.company = DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);
				}
				this.defaultFilterBy.dateFrom = this.dateFrom;
				this.defaultFilterBy.dateTo = this.dateTo;
				await this.resetFilters();
				
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);
	},
	methods: {
		onClickGenerate() {
			this.$bvModal.show('generate-stickers');
		},
		onGenerateSuccess() {
			this.$bvModal.hide('generate-stickers');

			this.retrieveData();
		},

		dateFromDisabled(_ymd, date) {
			return date > new Date();
		},
		isValidAssetCode(assetCode) {
			return _.isEmpty(assetCode) ||
				ValidationUtil.isValidAssetCode(this.allAssetTypesObj, assetCode);
		},
		validateFilter() {
			let isValid = true;

			if (_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo)) {
				this.$toaster.warning('Date From and Date To are required.');
				isValid = false;
			} else if ((_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo)) ||
				(!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))) {
				this.$toaster.warning('Invalid Date Range. Date From and Date To must both have value.');
				isValid = false;
			} else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
				this.$toaster.warning('Invalid Date Range. Date From must be less than Date To.');
				isValid = false;
			} else if (DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90) {
				this.$toaster.warning('Invalid Date Range. Data range is allowed up to 90 days difference.');
				isValid = false;
			} else if (!this.isValidAssetCode(this.filterBy.assetCode)) {
				this.$toaster.warning(
					`Invalid Asset Code. "${this.filterBy.assetCode}" doesn't follow any of your asset tagging format.`
				);
				isValid = false;
			}

			return isValid;
		},

		async onFilterRequest() {
			if (!this.validateFilter()) {
				return;
			}

			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				await this.retrieveData();
				this.prevFilter = { ...this.filterBy };
			}
		},
		async resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = { ...this.defaultFilterBy };
				this.prevFilter = { ...this.filterBy };

				await this.retrieveData();
			}
		},
		async retrieveData() {
			try {
				// Show Loader
				this.isLoading = true;

				let filter = { ...this.filterBy };
				filter.companyId = this.loggedUserCompany.id;
				filter.fromTimestamp = DateUtil.startDateTimeStamp(
					new Date(filter.dateFrom)
				);
				filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));

				if (this.isSuperAdmin) {
					const { data } = await stickerApi.getStickers(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);
					this.allStickersObj = data.stickers;
				} else {
					const { data } = await stickerApi.getStickers(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);
					this.allStickersObj = data.stickers;
				}

				this.processStickers(this.allStickersObj);
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},

		processStickers(stickers) {
			this.items = Object.values(stickers);
			this.items = _.sortBy(this.items, ['dateGenerated']);
			this.items.reverse();

			this.items.forEach((item) => {
				item['Date Generated'] = DateUtil.getFormattedDateWithTime(item.dateGenerated);
				item['totalAssetCount'] = item.stickers.length;
				item['stickerRange'] = item.stickers[0] + ' - ' + item.stickers.slice(-1)[0];
			});
			this.totalRows = this.items.length;

			// remove show details
			_.forEach(this.items, item => {
				delete item._showDetails;
			});

			// refresh table
			if (this.$refs.stickersTable) {
				this.$refs.stickersTable.refresh();
			}
		},
	},
};
</script>
<style>
.download {
	color: white !important;
}
</style>